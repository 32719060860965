//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    form: {
      type: Object,
      default: function _default() {
        return {
          // cname: 'aaa',
          // pay_time: '111111',
          // mobile: '12334545345',
          // audit_arr: [
          //     {
          //         audit_status: '2',
          //         audit_time: '2022.23.25',
          //         audit_rerson_name: '小王'
          //     },
          //     {
          //         audit_status: '1',
          //         audit_time: '2022.23.25',
          //         audit_rerson_name: '小王'
          //     },
          //     {
          //         audit_status: '-1',
          //         audit_time: '2022.23.25',
          //         audit_rerson_name: '小王'
          //     }
          // ]
        };
      }
    },
    data: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      // form: {
      //     cname: 'aaa',
      //     pay_time: '111111',
      //     mobile: '12334545345',
      //     audit_arr: [
      //         {
      //             audit_status: '2',
      //             audit_time: '2022.23.25',
      //             audit_rerson_name: '小王'
      //         },
      //         {
      //             audit_status: '1',
      //             audit_time: '2022.23.25',
      //             audit_rerson_name: '小王'
      //         },
      //         {
      //             audit_status: '-1',
      //             audit_time: '2022.23.25',
      //             audit_rerson_name: '小王'
      //         }
      //     ]
      // },
      formLabelWidth: '120px'
    };
  },
  methods: {
    showDialogFun: function showDialogFun() {
      this.dialogFormVisible = true;
    }
  }
};