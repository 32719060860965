var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialogDiv" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            title: "审核详情",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, disabled: true } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "客户名称：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input-class",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.cname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cname", $$v)
                      },
                      expression: "form.cname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "客户电话：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input-class",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "缴费日期：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input-class",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.pay_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pay_time", $$v)
                      },
                      expression: "form.pay_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核进度：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-steps",
                    { attrs: { direction: "vertical", active: 0 } },
                    _vm._l(_vm.form.audit_arr, function (item, index) {
                      return _c(
                        "el-step",
                        { key: index + "audit", attrs: { title: "" } },
                        [
                          _c("template", { slot: "title" }),
                          _vm._v(" "),
                          _c("template", { slot: "description" }, [
                            item.audit_status == 2
                              ? _c(
                                  "div",
                                  { staticClass: "green common-class" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-check status-icon",
                                    }),
                                    _vm._v(" "),
                                    item.audit_reason == ""
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "mar-l-r" },
                                            [_vm._v("已通过")]
                                          ),
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.audit_time) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "mar-l-r" },
                                            [_vm._v(_vm._s(item.audit_reason))]
                                          ),
                                        ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.audit_status == 1
                              ? _c(
                                  "div",
                                  { staticClass: "blue common-class" },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-time status-icon",
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "mar-l-r" }, [
                                      _vm._v("待审核"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.audit_status == -1
                              ? _c(
                                  "div",
                                  { staticClass: "error common-class" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-close status-icon",
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "mar-l-r" }, [
                                      _vm._v("驳回"),
                                    ]),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.audit_time) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "icon" }, [
                            _c("div", { staticClass: "name-icon" }, [
                              _vm._v(_vm._s(item.audit_rerson_name)),
                            ]),
                          ]),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }